
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import PolicyDocumentation from "@/views/underwriter/policies/PolicyDocumentation.vue";

export default defineComponent({
  name: "admin-policy-document-upload",
  components: { PolicyDocumentation },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Policy Documentation", [
        "Administrator",
        "Policies",
      ]);
      MenuComponent.reinitialization();
    });
  },
  created() {
    //Set page title
    document.title =
      "Administrator Policy Documentation | " + process.env.VUE_APP_TITLE;
  },
});
